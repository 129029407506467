<template>
  <main>
    <div v-if="spaces && spaces.length > 0" class="subnav-detail">
      <transition appear name="fade">
        <div v-if="spaces">
          <div v-for="space in spaces" :key="space.id" tag="div">
            <router-link
              :to="{
                name: 'SpaceDetails',
                params: { spaceId: space.id }
              }"
            >
              <div class="single row ">
                <div class="col-12 col-sm-6 col-lg-3">
                  <div class="building-thumb">
                    <img :src="space.floorPlanURLThumb" />
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-2">
                  {{ space.name }}
                </div>
                <div class="col-12 col-sm-6 col-lg-2">
                  Suite: {{ space.suiteNumber }}
                </div>
                <div class="col-12 col-sm-6 col-lg-2">
                  Available:
                  {{ space.availability }}
                </div>
                <div class="col-12 col-sm-6 col-lg-2">
                  {{ space.sizeUsable }} usqft
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="!spaces" class="subnav-detail">
      <transition appear name="slow">
        <p>
          Sorry, there are currently no offices available.
          <router-link :to="{ name: 'Contact' }">Contact us</router-link> to get
          on the waiting list.
        </p>
      </transition>
    </div>
    <div class="error">{{ errorSpace }}</div>
    <div v-for="space in spaces" :key="space.id"></div>
  </main>
</template>

<script>
import getBuildingByURL from "@/composables/getBuildingByURL";
export default {
  props: ["buildingId"],
  setup() {
    const { errorSpace, building, spaces } = getBuildingByURL(
      window.location.host
    );
    return { errorSpace, spaces, building };
  }
};
</script>

<style scoped>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 2s ease;
}

.slow-enter-from {
  opacity: 0;
}
.slow-enter-active {
  transition: all 4s ease;
}
</style>